@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";

.contentContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @include mixins.opacityTransition(0, 1);

  > * {
    flex-basis: calc(25% - 10px);
  }

  @for $i from 1 to 5 {
    > *:nth-child(#{$i}) { align-self: flex-end; }
  }
  @for $i from 5 to 9 {
    > *:nth-child(#{$i}) { align-self: flex-start; }
  }

  @media(max-width: $break-point-sm) {
    // on smaller screens only show 4 images
    > * {
      flex-basis: calc(50% - 10px);
    }

    @for $i from 1 to 3 {
      > *:nth-child(#{$i}) { align-self: flex-end; }
    }
    @for $i from 3 to 5 {
      > *:nth-child(#{$i}) { align-self: flex-start; }
    }
  }
}

.viewMoreButton {
  text-align: center;
  display: none;
  @media(max-width: $break-point-sm) {
    padding-top: 0.75rem;
    display: block;
  }
}

.sliderWrapper {
  transition: all 250ms ease;

  > button:first-of-type {
    position: absolute;
    right: 40px;
    top: 55px;
  }

  &Single {
    text-align: center;
  }
}

.imageWrapper {
  position: relative;

  &Hidden {
    opacity: 0.5;
  }
}

.photoDescription {
  position: absolute;
  bottom: 0;
  height: auto;
  width: 100%;
  padding: 20px;
  color: white;
  background-color: rgba(0, 0, 0, .4);
  max-height: 100px;
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
  mask-image: linear-gradient(180deg, #000 60%, transparent);

  > div:nth-of-type(1) { // title
    font-size: 1.2rem;
    font-weight: 600;
  }

  a {
    color: white;
    text-decoration: underline;
  }
}

.item {
  position: relative;
  width: 100%;
}

.placeholderDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #FAFAF7;
  text-align: center;

  span {
    margin-top: 150px;
  }

  &Hidden {
    visibility: hidden;
  }
}

.galleryItem {
  opacity: 0;
  transition: opacity .5s ease;
  display: block;
  overflow:hidden;
  position: relative;
  cursor: pointer;

  button {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &:hover {
    .editButton {
      visibility: visible;
    }
  }

  @for $i from 1 to 10 {
    &:nth-of-type(#{$i}) { transition-delay: $i * 0.15s; }
  }

  @include mixins.screenXs {
    iframe, video {
      pointer-events: none;
    }
  }
}

.coverImageContainer:after {
  content: "\F110";
  font-family: Flaticon;
  font-size: 6rem;
  position: absolute;
  right: calc(50% - 32px);
  top: calc(50% - 51px);
  color: white;
}

.editButton {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 5px;
  visibility: hidden;
  padding: 4px 6px;
}
